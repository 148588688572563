<section class="invitation partial-padding ">
    <label>Curriculum vitae 📋</label>
    <div class="description">
        <!-- <span>Check CV and Contact me</span> -->
    </div>
    <div class="bio-txt">
        <span>Explore my professional experience, skills, and achievements. Click below to download my latest CV and learn more about my career path.</span>
    </div>
    <div class="bio">
        <a style="width:90%;display:flex;justify-content:center" href="./../../../../assets/pdf/Tomasz_Chlebek_Resume.pdf" target="_blank" download="Resume_Tomasz_Chlebek.pdf"> <button class="cv-button">DOWNLOAD PDF </button></a>
    </div>

</section>